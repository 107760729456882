<template>
  <!-- Game Type6 (分類版塊) -->
  <div
    :id="'game-' + data.block"
    class="game-type6 my-2"
  >
    <div
      class="swiperBox d-flex"
      :style="{'height':`${swiperHeight}px`}"
    >
      <!-- 左邊tab -->
      <swiper
        ref="gameItem"
        :options="tabOption"
        class="myTab px-4 py-0"
      >
        <swiper-slide
          v-for="(item,index) in data.data.navbar"
          :key="item._id"
          style="width:auto"
        >
          <div
            class="game__item--card cursor-pointer"
            :class="{ 'swiper-slide-active': tabActiveIndex === index }"
            @click="getGameType(index)"
          >
            <div
              class="game-card d-flex justify-center flex-column align-center pt-1"
            >
              <img
                :src="baseImgUrl+item.nav_icon_light"
                height="20"
              >
              <strong
                :class="[tabActiveIndex === index ? 'white--text' : 'subtitle--text']"
              > {{ item.game_type }}</strong>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <!-- 右邊滑動區塊 -->
      <swiper
        ref="gameItem"
        :options="swiperOption"
        class="mySwiper px-4 py-0 pl-0"
      >
        <swiper-slide
          v-for="(item) in NewItemContent"
          :key="item._id"
          style="width:auto"
          :class="item.status==='0'?'maintenanceBox':''"
        >
          <!-- 維修中 父層要加 :class="item.status==='0'?'maintenanceBox':''"-->
          <maintenance v-if="item.status=='0'" />
          <div
            class="game__item--card cursor-pointer d-flex justify-center flex-column align-center"
            @click="lauchGame(item.game_code, item.hyperlink,item.class)"
          >
            <div
              class="itemContent d-flex flex-column justify-center align-center"
            >
              <img
                :src="baseImgUrl+item.logo "
                height="40px"
              >
              <strong>{{ item.title }}</strong>
              <div class="subtitle--text">
                {{ item.game_type }}
              </div>
            </div>
            <img
              :src="baseImgUrl+item.image_h5_2 "
              width="100%"
            >
            <span class="secondary--text">{{ item.class }}</span>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { goSlotPage } from '../../../../utils/slot'
import { resStatus } from '../../../../utils/resUtils'
import maintenance from '@/components/maintenance'
export default {
  components: { maintenance },
  props: {
    data: {
      type: Object,
      required: true,
    },
    // 寫死fix區塊總高度(下載app/navbar/fifaWorldCup)
    fixLayoutHeight: {
      type: Number,
      required: true,
    },
    // 需fix的動態元件總高度(banner/maraquee/
    apiFixComponentsHeight: {
      type: Number,
      required: true,
    },
    // fix的動態元件是否載入完成
    isParentRendered: {
      type: Boolean,
      required: true,
    },
    // 動態模塊數量
    layoutAreaLength: {
      type: Boolean,
      required: true,
    },

  },

  data: () => ({
    tabOption: {
      slidesPerView: 'auto',
      centeredSlidesBounds: true,
      direction: 'vertical', // 垂直滚动
      autoHeight: true, // 高度自适应
      observer: true,
      observeParents: true,
    },
    swiperOption: {
      slidesPerView: 'auto',
      centeredSlidesBounds: true,
      direction: 'vertical', // 垂直滚动
      autoHeight: true, // 高度自适应
      observer: true,
      observeParents: true,
    },
    NewItemContent: [],
    NewItemContentLength: [],
    tabActiveIndex: 0,
    maintenance: false, // 維修中

  }),

  computed: {
    ...mapGetters(['baseImgUrl', 'theme']),
    ...mapState({
      isLogin: state => state.user.isLogin,
      isLiffMode: state => state.line.liff.status,
    }),
    /**
     * 取得目前allGameList
     */
    itemContents() {
      return this.data.data.navbar
    },
    /**
     * 判斷現在是亮版或暗版 決定icon
     * @date 2023-01-08
     */
    navIcon(game) {
      return this.theme === 'dark' ? game.nav_icon_dark : game.nav_icon_light
    },
    /**
     * 取得allGameList6高度
     * @date 2023-04-17
     */
    swiperHeight() {
      const { apiFixComponentsHeight, fixLayoutHeight, currentDevice } = this
      const bottomNavigationHeight = (this.currentDevice === 'ios' ? 72 : 56) +
      (this.layoutAreaLength ? 50 : 10)
      return window.innerHeight - apiFixComponentsHeight - fixLayoutHeight - bottomNavigationHeight
    },
  },
  mounted() {
    // 取得中英文遊戲類型名稱
    this.getItemContent()
    // 监听窗口大小变化事件
    // window.addEventListener('resize', this.swiperHeight)
  },
  methods: {
    ...mapActions([
      'get_game_url',
      'show_alert',
      'set_progress_status',
      'get_game_url_by_device',
    ]),
    /**
     * 滑動至當前遊戲類型的遊戲廠商
     * @date 2024-04-10
     */
    slideTo(sumBeforeIndex) {
      this.$refs.gameItem.$swiper.slideTo(sumBeforeIndex - 1, 1000)
    },
    /**
     * 取得當前遊戲類型＆遊戲廠商順序加總
     * @date 2024-04-10
     */
    getGameType(index) {
      // 取得當前遊戲類型
      this.tabActiveIndex = index
      // 遊戲廠商順序加總
      const sumBeforeIndex = this.NewItemContentLength.slice(0, index).reduce((acc, curr) => acc + curr, 0) + 1
      this.slideTo(sumBeforeIndex)
    },
    /**
     * 組建gameList右邊區塊的遊戲廠商
     * @date 2024-04-10
     */
    getItemContent() {
      // 加入中英文遊戲類型名稱到新的陣列
      const addClass = this.itemContents.map(addClass => {
        for (const key in addClass.items) {
          addClass.items[key].class = addClass.class.toUpperCase()// 英文遊戲類型名稱
          addClass.items[key].game_type = addClass.game_type// 中文遊戲類型名稱
        }
        return addClass
      }).flat()

      // 組建遊戲廠商
      const gameArray = addClass.map(subArray => {
        return subArray.items
      }).flat()
      this.NewItemContent = gameArray

      // 組建遊戲廠商總數
      const gameArrayLength = this.itemContents.map(subArray => {
        return subArray.items.length
      }).flat()
      this.NewItemContentLength = gameArrayLength
    },

    /**
     * 啟動遊戲 OR slot 列表頁
     * @date 2021-07-02
     * @param {string} gameCode 遊戲代碼
     * @param {string} hyperlink 遊戲代碼
     */
    lauchGame(gameCode, hyperlink, gameType) {
      // 如果尚未登入
      if (!this.isLogin) {
        return this.show_alert({
          icon: 'fail',
          text: this.$t('flashMessage.loginFirst'),
        })
      }
      // 如果為 slot fish 爾且不是遊戲清單，就到 slot 頁面
      const goSlotPageItems = ['SLOT', 'FISH']
      if (goSlotPageItems.includes(gameType)) return this.goSlotPage(hyperlink)

      console.log(`開啟遊戲 liff mode:${this.isLiffMode}`)

      const ua = navigator.userAgent.toLowerCase()
      console.log(ua)
      console.log('LINE browser: ' + ua.indexOf('line'))

      // 不為 liff 模式下 也不為 line 開啟
      if (!this.isLiffMode || ua.indexOf('line') < 0) {
        if (hyperlink === '0') return false
        // 如果Tab是遊戲類型，gameType為 live || lottery 類型，則另開視窗 游戲
        if (gameType === 'LIVE' || gameType === 'LOTTERY') return this.goBlankPage(gameCode)
      }

      // 進入嵌入遊戲頁面
      this.$router.push({
        name: 'launchGame',
        query: {
          t: gameCode,
          redirectUrl: this.$route.path,
        },
      })
    },
    /**
     * 進入遊戲列表頁面
     * @date 2021-07-02
     * @param {string} hyperlink 遊戲代碼
     */
    goSlotPage(hyperlink) {
      goSlotPage(hyperlink)
    },
    /**
     * 另開遊戲視窗 (live)
     * @date 2021-08-04
     * @param {string} gameCode 遊戲代碼
     */
    async goBlankPage(gameCode) {
      this.set_progress_status(true)
      this.getGameUrl(gameCode)
    },
    /**
     * 取得遊戲 url (成功)
     * @date 2021-07-02
     * @param {object} gameUrl 遊戲連結 url
     */
    getGameUrlSuccess(gameUrl, windowObj) {
      this.show_alert({ icon: 'done' })
      this.gameUrl = gameUrl.url
      this.set_progress_status(false)
      windowObj.location.href = gameUrl.url
    },

    /**
     * 取得遊戲 url (失敗)
     * @date 2021-07-02
     */
    getGameUrlFail() {
      this.set_progress_status(false)
      this.show_alert({ icon: 'fail' })
      this.$router.replace({ path: '/' })
    },
    /**
     * 取得遊戲 url
     * @date 2021-07-02
     * @param {string} gameCode 遊戲代碼
     */
    async getGameUrl(gameCode) {
      const windowObj = window.open('', '_blank')
      const res = await this.get_game_url_by_device({ gamecode: gameCode, is_mobile: 1 })
      resStatus(res, this.getGameUrlSuccess, this.getGameUrlFail, windowObj)
    },
  },
}
</script>

<style lang="scss" scoped>
#game-GameL6{
  .myTab{
    width: 15%;
    min-width: 95px;
    img{
      filter: grayscale(100%);
    }
    .swiper-slide .swiper-slide-active{
      img{
        filter: brightness(90);
      }
      .game-card{
        background-image: url('~@/assets/icons/block/game/type6/tabBgActive.png');
      }
    }
    .game-card{
      background-image: url('~@/assets/icons/block/game/type6/tabBg.png');
      background-repeat: no-repeat;
      background-size: 100%;
      height: 55px;
      padding-bottom: 15px;
      max-height: 74px;
      max-width: 60px;
    }
  }
  .mySwiper{
    width: 85%;
    .swiper-slide{
      margin-bottom: 20px;
      border-radius: 20px;
      overflow: hidden;
    }
    .game__item--card{
      background-image: url('~@/assets/icons/block/game/type6/bg.png');
      border-radius:15px ;
      overflow: hidden;
      position: relative;

      .itemContent,span{
        position: absolute;
        left: 0;
      }
      .itemContent{
        top: 0;
        width: 45%;
        height: 100%;
        strong{
          font-size: 5.5vw;
          line-height: 1.3;
          &+div{
            font-size: 2.7vw;
          }
        }
      }
      span{
        bottom:-30px;
        font-weight: 900;
        font-size: 65px;
        opacity: 0.1;
      }
    }
  }

  .swiper-slide{
    width: 100% !important;
  }
}

</style>
