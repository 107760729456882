// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/icons/block/game/type6/tabBgActive.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/icons/block/game/type6/tabBg.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@/assets/icons/block/game/type6/bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/* Globals vuetify 客製變數 */\n#game-GameL6 .myTab[data-v-2d44e4c2] {\n  width: 15%;\n  min-width: 95px;\n}\n#game-GameL6 .myTab img[data-v-2d44e4c2] {\n  filter: grayscale(100%);\n}\n#game-GameL6 .myTab .swiper-slide .swiper-slide-active img[data-v-2d44e4c2] {\n  filter: brightness(90);\n}\n#game-GameL6 .myTab .swiper-slide .swiper-slide-active .game-card[data-v-2d44e4c2] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n#game-GameL6 .myTab .game-card[data-v-2d44e4c2] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-repeat: no-repeat;\n  background-size: 100%;\n  height: 55px;\n  padding-bottom: 15px;\n  max-height: 74px;\n  max-width: 60px;\n}\n#game-GameL6 .mySwiper[data-v-2d44e4c2] {\n  width: 85%;\n}\n#game-GameL6 .mySwiper .swiper-slide[data-v-2d44e4c2] {\n  margin-bottom: 20px;\n  border-radius: 20px;\n  overflow: hidden;\n}\n#game-GameL6 .mySwiper .game__item--card[data-v-2d44e4c2] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n  border-radius: 15px;\n  overflow: hidden;\n  position: relative;\n}\n#game-GameL6 .mySwiper .game__item--card .itemContent[data-v-2d44e4c2], #game-GameL6 .mySwiper .game__item--card span[data-v-2d44e4c2] {\n  position: absolute;\n  left: 0;\n}\n#game-GameL6 .mySwiper .game__item--card .itemContent[data-v-2d44e4c2] {\n  top: 0;\n  width: 45%;\n  height: 100%;\n}\n#game-GameL6 .mySwiper .game__item--card .itemContent strong[data-v-2d44e4c2] {\n  font-size: 5.5vw;\n  line-height: 1.3;\n}\n#game-GameL6 .mySwiper .game__item--card .itemContent strong + div[data-v-2d44e4c2] {\n  font-size: 2.7vw;\n}\n#game-GameL6 .mySwiper .game__item--card span[data-v-2d44e4c2] {\n  bottom: -30px;\n  font-weight: 900;\n  font-size: 65px;\n  opacity: 0.1;\n}\n#game-GameL6 .swiper-slide[data-v-2d44e4c2] {\n  width: 100% !important;\n}", ""]);
// Exports
module.exports = exports;
